import cn from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC } from 'react'

interface menuItem {
  label: string
  link: string
}

interface Props {
  title: string
  menuItems: menuItem[]
}

const PagesSideBar: FC<Props> = ({ title, menuItems }) => {
  const { asPath } = useRouter()
  // className={cn({ selected: asPath === i?.link })}
  if (menuItems.length === 0) return <></>

  return (
    <>
      <div className="title font-bold border-b border-white pb-12 body">{title}</div>
      <ul className="my-8">
        {menuItems.map((i, index) => {
          return (
            <li key={index}>
              <Link
                href={i?.link || '/'}
                passHref
                className={cn('block pb-12 body', { 'text-terrcotta': asPath === i?.link })}
              >
                {i.label}
              </Link>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default PagesSideBar
