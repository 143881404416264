import commerceConfig from '@commerce/config'
import { normalizeHit } from '@commerce/utils'
import { MobilePageSideBar, PagesSideBar } from '@components/aux'
import MobilePageMenuButton from '@components/aux/MobilePageMenuButton'
import { Breadcrumbs, Layout, Loader, SeoHead } from '@components/common'
import AlgoliaCollection from '@containers/AlgoliaCollection'
import SliceZone from '@containers/SliceZone'
import { ServerSearchClient } from '@lib/algolia'
import { BuildTypes } from '@lib/general'
import getGlobalStaticProps from '@lib/globalStaticProps'
import { getConfigByLocale } from '@lib/locale'
import { buildDefualtFilterString } from '@lib/plp'
import { findPLPDocId, getDocById, getDocByUid, getSingleDocByType, prismicPreviewData } from '@lib/prismic'
import * as Sentry from '@sentry/nextjs'
import cn from 'classnames'
import otherPriorityPageURLs from 'config/otherPriorityPageURLs.json'
import type { GetStaticPathsContext, GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { Hit } from 'react-instantsearch-core'
import { findResultsState } from 'react-instantsearch-dom/server'

export async function getStaticProps({
  params,
  preview,
  previewData = {},
  locale,
}: GetStaticPropsContext | prismicPreviewData) {
  const { ref } = previewData
  const { handle } = params

  const globalProps = await getGlobalStaticProps(ref, locale)

  let doc = null
  let pagesMenu = null
  let plpDoc = null
  let facetSettings = null
  let colourSwatches = null
  const searchState = {}
  const algoliaIndex = getConfigByLocale(locale, 'algoliaProductIndex')

  //check for . in the URL
  const checkPath = handle.join('/')
  if (checkPath.includes('.')) {
    return { notFound: true }
  }

  if (Array.isArray(handle) && handle.length === 1 && handle[0] === 'homepage') {
    return { notFound: true }
  }

  // 500 demo
  if (checkPath.includes('500-demo')) {
    throw new Error('Internal server error')
  }

  try {
    doc = await getDocByUid('general', handle.join('.'), ref, locale)
    pagesMenu = await getSingleDocByType('pages_menu', ref, locale)
    colourSwatches = await getSingleDocByType('colour_swatches', ref, locale)

    const plpDocId = findPLPDocId(doc?.data?.body1)
    if (plpDocId) {
      const plpResult = await getDocById(plpDocId, ref, locale)
      facetSettings = colourSwatches

      if (plpResult) {
        const resultsState = await findResultsState(AlgoliaCollection, {
          searchClient: ServerSearchClient,
          indexName: getConfigByLocale(locale, 'algoliaProductIndex'),
          searchState,
          filterString: buildDefualtFilterString(plpResult?.data?.default_filters || []),
          defaultFilters: plpResult?.data?.default_filters || [],
        })
        const parsedResultState = JSON.parse(JSON.stringify(resultsState))
        const hits = parsedResultState?.rawResults?.[0]?.hits?.map((hit: Hit) => normalizeHit(hit))
        if (hits.length > 0) parsedResultState.rawResults[0].hits = hits
        plpDoc = {
          doc: plpResult?.data || null,
          indexName: algoliaIndex,
          searchState,
          resultsState: parsedResultState,
        }
      }
    }
  } catch (err) {
    Sentry.captureException(err)
    console.error('pages', err)
  }

  if (!doc) {
    return { notFound: true }
  }

  return {
    props: {
      //shopifyCollection,
      ...globalProps.props,
      preview: preview || false,
      doc: doc?.data || null,
      uid: doc?.uid || null,
      pagesMenu: pagesMenu?.data || null,
      plpDoc,
      colourSwatches: colourSwatches?.data?.colours || null,
      facetLabelMappings: facetSettings?.data?.mapping || null,
    },
    // revalidate: 86400, //24 hours,
  }
}

export async function getStaticPaths({ locales }: GetStaticPathsContext) {
  // const config = { locales }
  const {
    config: { defaultLocale, fakeLocale },
  } = commerceConfig
  // const allGeneralPages = await queryAll(
  //   { query: GET_ALL_GENERAL_PAGES_FOR_FEED, variables: { lang: defaultLocale } },
  //   'allGenerals',
  //   100
  // )

  const buildType = process?.env?.BUILD_TYPE || BuildTypes.PART
  if (buildType === BuildTypes.NONE) {
    return {
      paths: [],
      fallback: true,
    }
  }

  const priorityPageURLs = otherPriorityPageURLs
  const allPaths = priorityPageURLs
    .map((u) => {
      const handle = u.split('/').slice(2)
      return { params: { handle }, locale: u.split('/')[1] }
    })
    ?.flat()
  const alreadyExists = allPaths
    ?.map((p) => p?.params?.handle?.join('/'))
    ?.filter((value, index, self) => self.indexOf(value) === index)

  const footerItems = await getSingleDocByType('footer_n', null, defaultLocale)
  const footerLinks = footerItems?.data?.body
    ?.map((c: any) => c?.items)
    ?.flat()
    ?.map((i: any) => i?.link)
    ?.filter((l: any) => l)
    ?.filter((l: any) => !l.toLowerCase().startsWith('/product/'))
    ?.filter((l: any) => !l.toLowerCase().startsWith('/api/'))
    ?.filter((l: any) => !l.toLowerCase().startsWith('/me/'))
    ?.filter((l: any) => !(l === '/me'))

  footerLinks?.forEach((link: any) => {
    if (!alreadyExists?.includes(link)) {
      locales
        ?.filter((l) => l !== fakeLocale)
        .map((l) => {
          const handle = link.split('/')?.filter((s: any) => s && s !== '')
          allPaths?.push({
            params: { handle },
            locale: l,
          })
        })
    }
  })
  return {
    paths: process.env.VERCEL_ENV === 'preview' || !allPaths ? [] : allPaths,
    // paths: !allPaths ? [] : allPaths,
    fallback: true,
  }
}

export default function General({
  doc,
  pagesMenu,
  plpDoc,
  colourSwatches,
  facetLabelMappings,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const router = useRouter()
  const [showMobileNav, setShowMobileNav] = useState(false)
  const [isMenuTriggersticky, setIsMenuTriggerSticky] = useState(false)
  const menuTriggerRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const handleScroll = () => {
    if (menuTriggerRef && menuTriggerRef?.current) {
      setIsMenuTriggerSticky(menuTriggerRef.current.getBoundingClientRect()?.top <= 60)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])
  const { body, breadcrumb, seo_title, seo_description, seo_opengraph_image, title, show_page_title, body1 } = doc || {}
  const { title: menuTitle, menu } = pagesMenu || {}

  const handleCloseMobileNav = () => {
    setShowMobileNav(false)
  }
  const handleShowMobileNav = () => {
    setShowMobileNav(true)
  }
  const auxPage: any = body || []

  if (router.isFallback) {
    return <Loader title="product-loader" />
  }

  return (
    <>
      <SeoHead title={seo_title || ''} description={seo_description || ''} image={seo_opengraph_image} />
      <div className="">
        {auxPage && auxPage.length > 0 && (
          <>
            <div className="py-8 laptop:py-0">
              <div className="wrapper laptop:mb-16">
                <Breadcrumbs crumbs={breadcrumb} generateBreadCrumbJson={true} />
              </div>
            </div>
            <div
              className={cn('sticky top-60 transition-all duration-150 bg-ecru z-30 tablet:hidden', {
                'fixed-component-shadow': isMenuTriggersticky,
              })}
              ref={menuTriggerRef}
            >
              <div className="wrapper py-12">
                <MobilePageMenuButton title={menuTitle} onOpen={handleShowMobileNav} />
              </div>
            </div>
            <div className="wrapper mt-28 tablet:mt-16 grid gap-16 tablet:grid-cols-12">
              <div className="hidden tablet:block col-start-2 col-span-2">
                <PagesSideBar title={menuTitle} menuItems={menu} />
              </div>
              <div className="pb-40 tablet:pb-80 tablet:col-start-4 tablet:col-span-8 laptop:col-start-4 laptop:col-span-6 ">
                {show_page_title !== false && <h1 className="heading-5 mb-32">{title}</h1>}
                <SliceZone slices={auxPage || []} applyWrapper={false} widthSideMenu={true} />
              </div>
            </div>
          </>
        )}
      </div>
      <SliceZone
        slices={body1 || []}
        plpDoc={plpDoc}
        facetLabelMappings={facetLabelMappings}
        colourSwatches={colourSwatches}
      />
      {auxPage && auxPage.length > 0 && (
        <MobilePageSideBar
          title={menuTitle}
          menuItems={menu}
          onClose={handleCloseMobileNav}
          showMobileNav={showMobileNav}
          setShowMobileNav={setShowMobileNav}
        />
      )}
    </>
  )
}

General.Layout = Layout
