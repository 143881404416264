import Plus from '@components/icons/Plus'
import { useUI } from '@components/ui'

interface Props {
  title: string
  onOpen: () => void
}

const MobilePageMenuButton = ({ title, onOpen }: Props) => {
  const { openHelpMenu } = useUI()
  const handleShowMobileNav = () => {
    onOpen()
    openHelpMenu()
  }

  return (
    <button onClick={handleShowMobileNav} className="border border-charcoal title-small p-8 text-center w-full">
      {title}
      <div className="absolute top-20 right-32">
        <Plus width="18" height="18" />
      </div>
    </button>
  )
}

export default MobilePageMenuButton
