import { ChevronRight } from '@components/icons'
import { useUI } from '@components/ui'
import SlideInSidebar from '@components/ui/SlideInSidebar'
import cn from 'classnames'
import Link from 'next/link'
import router, { useRouter } from 'next/router'
import { FC, useEffect } from 'react'

interface menuItem {
  label: string
  link: string
}

interface Props {
  title: string
  menuItems: menuItem[]
  onClose: () => void
  showMobileNav: boolean
  setShowMobileNav: (showMobileNav: boolean) => void
}

const MobilePagesSideBar: FC<Props> = ({ title, menuItems, onClose, showMobileNav, setShowMobileNav }) => {
  const { asPath } = useRouter()
  const { closeHelpMenu } = useUI()
  // className={cn({ selected: asPath === i?.link })}

  useEffect(() => {
    const handleRouteChange = () => {
      setShowMobileNav(false)
      closeHelpMenu()
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [closeHelpMenu, setShowMobileNav])

  if (menuItems.length === 0) return <></>

  const handleCloseMobileNav = () => {
    onClose()
    closeHelpMenu()
  }

  return (
    <SlideInSidebar
      display={showMobileNav}
      closeButton={false}
      onClose={handleCloseMobileNav}
      orientation="bottom"
      className={cn('h-3/4', {
        'translate-y-0': showMobileNav,
        'translate-y-full': !showMobileNav,
      })}
    >
      <div className="">
        <div className="flex justify-between fixed-component-shadow px-20 py-16">
          <div className="title  border-b border-white ">{title}</div>
          <button onClick={onClose} className="body-link">
            Close
          </button>
        </div>
        <ul className="pb-28 px-20 bg-ecru">
          {menuItems.map((i, index) => {
            return (
              <li key={index}>
                <Link
                  href={i?.link || '/'}
                  passHref
                  className={cn('flex justify-between py-16 body border-b border-lightGrey ', {
                    'text-terrcotta': asPath === i?.link,
                  })}
                  legacyBehavior
                >
                  <>
                    {i.label}
                    <ChevronRight width="20" height="20" />
                  </>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </SlideInSidebar>
  )
}

export default MobilePagesSideBar
